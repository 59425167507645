#UserShopContainer{
    margin:auto;
	/* width: 80%; */
	margin-bottom: 30px;
}
.css-13xfq8m-MuiTabPanel-root{
    padding: 16px 0px !important;
}
.css-v0cfbd-MuiGrid-root{
    margin-left: 0px !important;
}
#UserShopContainer p{
    text-align: center;
}
#banner{
    height: 300px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    /* , url("assets2/images/shop-banner-yellow.png") */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
#banner img{
    width: 100%;
    margin: -16px 0px;
    height: 300px;
}
div#shop-info{
    padding: 30px 150px;
}
#shop-info p{
    color: black;
    font-size: 14pt;
    margin: auto;
    
}
#shop-name{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    border: 2px solid white;
    border-radius: 30px;
    padding: 10px;
    background: rgba(0,0,0,0.4);
    font-size: 22pt;
    font-weight: 700;
}
#UserShopContainer.css-15j6ooq-MuiGrid-root{
    flex-basis: 90% !important;
    max-width: 90% !important;
}

#edit-button button{
    /* background-color: purple; */
    /* top: 140px;
    right: 30px; */
    display: flex;
    position: fixed;
    right: 50px;
    top: 150px;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 8px;
    padding: 3px 9px;
    background-color: rgba(100,100,100,0.2);
    /* height: 10%; */
}
/* edit button styling */
#edit-button button p{
    font-size: 14pt;
    padding-top: 10px;
    /* left: calc(100% - 121px); */
   
}
#edit-button button img{
    /* background-color: purple; */
    /* display: flex; */
    height: 40px;
}
/* edit modal styling */
.upload-button{
    color: white;
    background-color: #0066FF;
    font-size: 10pt;
    border: 2px solid #0066FF;
    border-radius: 5px;
}
#modal-modal-title{
    margin-bottom: 10px;
}




/* large screens */
@media (min-width: 1500px){
	#UserShopContainer.css-un48xx-MuiGrid-root
    {
		width: 400% !important;
	}
}
@media (min-width: 1200px){
    .css-7tryzw-MuiGrid-root {
        flex-basis: 300px !important;
    }
    .css-15j6ooq-MuiGrid-root {
        flex-basis: 100px !important;
        max-width: 100% !important;
    }
    /* #edit-button button{
        left: 1450px;
    } */
    
}
@media (max-width: 990px){
    #edit-button button{
        top: 195px; 
        right: 30px;
    }
}
/* mobile */
@media (max-width: 500px){
    #UserShopContainer{
        width: 100% !important;
    }
    #shop-name p{
        font-size: 21px !important;
    }
    div#shop-info{
        padding: 30px 50px;
    }
    .css-15j6ooq-MuiGrid-root{
        margin: auto !important;
        flex-basis: 70% !important;
    }
    #edit-button button{
        right: 20px;
    }
    
}
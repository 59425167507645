a {
  text-decoration: none;
}  

.white-para {
  color: #FFFFFF;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 83px !important;
  left: 650px !important;
} */

.navbar-collapse {
    width: 517px;
    /* position: fixed; */
    left: 0px;
    background-color: #FFFFFF;
    display: block;
    z-index: 9999;
    height: 100%;
    top: 0px;
    transition: left .5s;
}
.close-menu {
    position: absolute;
    right: 0px;
    top: 15px;
    padding: 5px 15px;
    color: #fff !important;
    z-index: 999
}

#menu-appbar .css-177ic5c{
  top: 70px !important;
}

.menu-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}

.bottom-fixed-section-fab-button {
  position: sticky;
  bottom: 0;
  text-align: center;
}

.top-fixed-section-fab-button {
  position: sticky;
  top: 0;
  text-align: center;
  z-index:1
}

.top-fixed-section {
    position: sticky;
    top: 0;
}

.top-fixed-section li.user-tab {
    background-color: #0068FF;
    padding-left: 60px;
}

.top-fixed-section li.user-tab a {
    color: #fff;
    display: flex;
    align-items: center;
}

li.user-tab img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

li.menu-tab, .inner-menu-section ul li:hover {
    background-color: #EFEFEF;
}

.header-category-menu  {
  position: relative;
}

/* .header-select-wrap .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  height: 350px !important;
} */

.MuiMenu-list {
  position: relative;
}

.down-arrow-icon {
  height: 45px;
  width: 45px;
  background: #0066FF;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.down-arrow-icon .fa-chevron-down{
  font-size: 20px;
  color: #fff;
}

.top-fixed-section li.menu-tab a, .inner-menu-section ul li a {
    color: #000000;
    text-decoration: none;
}
.header-menu-item {
  display: block !important;
  padding: 5px !important;
}

.inner-menu-section ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.inner-menu-section ul li.menu-heading{
    color: #0068FF;
    font-size: 16px;
    padding-left: 20px;
}
.inner-menu-section ul li a span {
    font-size: 18px;
    color: #000000;
}

.top-fixed-section ul li {
    display: inline-block;
    line-height: 60px;
    font-size: 20px;
    padding: 0px 20px;
    width: 100%;
    font-weight: bold;
}

.inner-menu-section ul li {
    display: inline-block;
    line-height: 50px;
    padding: 0px 20px 0 40px;
    font-size: 20px;
    color: #030303;
    width: 100%;
    font-weight: bold;
}

.inner-menu-section ul li.menu-active {
    padding: 0 20px;
}

.inner-menu-section ul li.menu-active a {
    color: #0066FF;
    cursor: pointer !important;
}

body.overflow {
    overflow-y: hidden;
    /* overflow-x: inherit !important; */
}

a.close-menu i {
    font-size: 25px;
}

.inner-menu-section {
    height: calc(100vh - 8rem);
    overflow-y: auto;
}

.search-provider > div {
  position: relative !important;
}

.hamburger-text {
  width: 65px;
  color: #000;
  font-weight: 500;
  font-size: 13px;
}
.search-provider ul{
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 10px;
    max-height: 350px;
    overflow-y: auto;
    border-radius: 10px;
}
.search-provider ul li{
    padding: 10px;
    /* background: #f0f0f0; */
    transition: 0.1s;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.search-provider ul li .img-wrap{
    height: 40px;
    width: 40px;
}

.search-provider ul li .img-wrap img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search-provider ul li:hover{
  /* background: linear-gradient(
    136deg
    , #F7BC06 0%, #E9B106 36%, #7C5E03 100%) 0% 0%; */
    background: #0066FF;
    color: #fff;
    font-weight: 600;
}

.css-1h5ew8f-ComboboxInner {
  top: -4px !important;
}

.sidebar-bottom a {
    display: inline-block;
    line-height: 60px;
    padding: 0px 20px 0 40px;
    font-size: 20px;
    color: #030303;
    width: 100%;
    font-weight: bold;
}

#menu-appbar .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 70px !important;
  left: auto !important;
  right: 129px;
}

#menu-appbar .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper ul {
  padding: 0;
}

#menu-appbar .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper li {
  /* font-size: 14px; */
  display: block;
}

#menu-appbar .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper li:hover {
  background: #0066FF ;
  color: #fff ;
}

#menu-appbar ul li {
  padding: 6px 16px !important;
}

/* NEW HEADER CSS START FROM HERE  */

.hamburger-text{
  margin-top: 0px;
}
.heder-right ul li a,  .heder-right ul li a span{
  display: block;
  text-align: center;
}
.heder-right ul li{
  margin:0 20px ;
}
.heder-right ul li a{
  position: relative;
}
.heder-right ul li a span{
  font-size: 12px;
  line-height: 13px;
  display: flex;
  justify-content: center;
  left: 0;
  width:100%
}
.heder-right ul li a img{
  margin-right: 0;
}
.heder-right ul li:after {
 top:8px;
 height: 20px;
 right: -20px;
}

.heder-right ul li:last-child::after {
  top: 18px;
}

.heder-right .btn-primary{
  text-align: center;
  margin-left: 10px;
  background: #0066FF;
  color: #fff  !important;
  text-decoration: none;
  font-size: 26px;
  padding: 10px !important;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
}
.header-search input, .header-search input.focus-visible-added{
  height: 69px !important;
  top: -8px;
  padding-right: 4.2%;
}

.header-search .header-search-btn{
  top: 9px;
  right: 12px;
}
.header-search{
 margin:0 0 1% 20px;
 position: relative;
}

.css-1tutrsd-ViewOption {
  padding-bottom: 20px;
}

.css-1xwysw7-ComboboxInner{
  line-height: 2.5 !important;
}

.header-select {
  position: absolute !important;
  right: 65px;
  top: -55px;
  border: none;
  background: transparent;
  color: black;
}

.header-select:focus-visible {
  outline: none !important;
}

.header-select option {
  padding: 10px;
}

.header-select .MuiOutlinedInput-notchedOutline {
  border: none;
}

header .MuiInputLabel-root {
  right: 105px;
  left: auto;
  top: -26px;
}

.form-control--view {
  position: absolute !important;
  top: 0 !important;
  right: 3.5rem !important;
  border: none !important;
}


/* NEW HEADER CSS END HERE  */

@media (max-width: 1199px) {
  .search-provider ul li p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 20px;
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .header-search {
      margin: 0px;
  }
  .header-search .header-search-btn{
    margin-top: 1%!important;
    right: 12px;
  }
}

@media (max-width: 767px) {
  .inner-menu-section ul li.menu-active, .inner-menu-section ul li, .top-fixed-section ul li {
      padding:  0 10px;
  }
  .top-fixed-section ul li, .inner-menu-section ul li, .inner-menu-section ul li a span {
      font-size: 15px;
  }

  a.close-menu i {
      font-size: 20px;
  }

  .header-flag {
    display: none;
  }

  .header-search {
    margin: 0;
  }
  #menu- .MuiMenu-paper{
    /* border: 5px solid orange; */
    /* margin: 10px 50px; */
    width: calc(100% - 150px);
    height: calc(100% - 150px);
    margin-top: 3.5%;
    min-width: 210px!important;
    margin-left: 7%;
  }

  .navbar-collapse {
      width: 280px;
      left: 0px;
  }
  .search-provider ul li {
    padding: 5px 0px;
    transition: 0.1s;
    font-weight: 600;
    font-size: 12px;
  }
  .heder-right ul li a span.header-text  {
    display: none;
  }
  #register-label{
    display:block;
    display: none;
  }
  .heder-right ul li {
    margin: 0 15px;
  }
  .heder-right .btn-primary{
    font-size: 22pt;
    margin-left: 0;
  }
  .header-content {
    padding: 20px 4px;
  }

  .header-search .header-search-btn{
    margin-top: 1%;
    right: 12px;
  }
}

.position-hamburger{
  margin-left: 20px;
}


@media (max-width: 620px) {
  .heder-right{ 
    display: flex;
    justify-content: flex-end;
    padding: 0px;
  }
  .heder-right ul{
    margin-right: 0px;
    margin-left: 10px;
  }
  .heder-right ul li {
    margin: 0 8px;
  }
  .heder-right .btn-primary{
    font-size: 19pt;
    margin-left: 0;
  }
  .position-hamburger {
    margin-left: 15px;
  }
  .hamburger-text{
    font-size: 8pt !important;
    padding-left: 10px;
  }
  #register-label{
    font-size: 8pt !important;
  }
  /* category dropdown height displacement for mobile */
  div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    /* top:134px !important; */
    width: 60%;
    min-width: 60px !important;
    /* margin: 0px 60px; */
  }
  
  
  
}
/* iphone X == 375px width */
@media (max-width: 416px) {
  header {
    /* background: #fff; */
    margin-bottom: 77px;
  }
  .header-content .brand-logo img {
    width: 100%;
    margin-left: -15px;
  }
  .heder-right ul {
    margin-right: 0px;
    margin-left: -20px;
  }
  .toggle-bar-icon {
    font-size: 23px;
  }
  .hamburger-text {
    font-size: 8pt !important;
    padding-left: 0px;
  }
  .heder-right ul li {
    margin: 0 4px;
    height: 36px;
    width: 38px;
  }
  .MuiSvgIcon-root {
    font-size: 2.1rem !important;
  }
  .heder-right .btn-primary {
    font-size: 14pt;
    margin-left: 0;
  }
  #favorite-icon{
    font-size: 30px !important;
  }

}

/* extra small phones */
/* works down to 303px wide */
@media (max-width: 350px) {
  .heder-right .btn-primary {
    font-size: 13pt;
    margin-left: 0;
    padding: 10px 7px !important;
  }
  .MuiSvgIcon-root{
    font-size: 31px !important;
  }
  .heder-right ul li{
    margin: 3px;
    height: 32px;
    width: 32px;
  }
  .position-hamburger {
    margin-left: 14px;
  }
  .hamburger-text .hamburger-label{
    font-size: 9px !important;
  }
  .header-content .brand-logo{
    width: 90px;
  }
  /* .header-content .brand-logo img{
    width: 74%;
  } */
  #favorite-icon{
    font-size: 30px !important;
  }
  #register-label{
    font-size: 6pt !important;
  }
}

.cursorPointer{
  cursor: pointer;
}
@media (max-width: 620px) {
  .header-right__wishlist{
    display: none;
  }
}
@media (max-width: 416px){
  .header-content {
    padding: 20px 11px;
}
}
